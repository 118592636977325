.Footer {
    display:flex;
    flex-direction: row;
    text-align:center;
    justify-content: space-evenly;
    align-items:center;
    margin-top:auto;
    width:100%;
    position:sticky;
    bottom:0px;
    left:0px;
    height:auto;
    padding:10px;
    border-style:solid;
    border-width:0.5px 0 0 0;
    border-color:var(--blueaccent);
    background-color: var(--boxbackground);
    box-shadow: inset 0px 2px 5px var(--blueaccent);
}

a {
    text-decoration:none;
}

@media screen and (orientation: landscape) {
    .footerbuttons {
        height: 2vh;
    }
}

@media screen and (orientation: portrait) {
    .footerbuttons {
        height: 2.3vw;
    }
}

.footerbuttons {
    max-width:100%;
    fill: var(--standardyellowtext);
}

.footerbuttons:hover {
    fill: var(--texthover);
}

.footerbuttons:active {
  fill: var(--texthover);
}
