.Header {
    display:flex;
    flex-direction: column;
    text-align:center;
    justify-content: center;
    align-items:center;
    margin:0px;
    padding:0px;
    border-style:solid;
    border-width:0 0 0.5px 0;
    border-color:var(--blueaccent);
    box-shadow: inset 0 -10px 5px -8px var(--blueaccent);
    background-image: url("../images/stars_background2_50.jpg");
    background-repeat: no-repeat;
    background-size: cover;
}

.logos {
  display:flex;
  flex-direction: row;
  margin:15px auto 5px auto;
  justify-content: center;
}

  .navbar {
    list-style-type: none;
    display:flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-items:center;
    justify-content: space-between;
    min-width:50%;
    margin:10px auto 5px auto;
    padding:0px;
  }

  .svgbuttons {
    height: 3.5vh;
    max-width:100%;
    fill: var(--standardyellowtext);
    margin:0 1.5vh 0 1.5vh;
}

  .svgbuttons:hover {
    fill: var(--texthover);
}

.svgbuttons:active {
  fill: var(--texthover);
}

  .navbar li {
    margin:0px 10px 0px 10px;
  }

  .navbar li a {
    text-decoration: none;
  }

.textlogo {
    height:10vw;
    margin:0px;
  }

  .imagelogo {
    height:10vw;
    margin:0px;
  }

  @media (max-width: 768px) {
    .textlogo {
      height:12vh;
      margin:0px;
    }
  
    .imagelogo {
      height:12vh;
      margin:0px;
    }

    .navbar {
      list-style-type: none;
      display:flex;
      flex-wrap: wrap;
      flex-direction: column;
      align-items:center;
      justify-content: space-between;
      min-width:50%;
      margin:5px auto 5px auto;
      padding:0px;
    }

    .navbar li {
      margin:0px 5px 5px 5px;
    }

    .svgbuttons {
      height: 2.6vh;
  }
  }

  @font-face {
    font-family: "Star Jedi";
    src:url('../fonts/StarJedi-DGRW.ttf') format('truetype');
    font-weight:normal;
    font-style:normal;
  }
  
  @font-face {
    font-family: "Star Jedi Hollow";
    src:url("../fonts/StarJediHollow-A4lL.ttf") format("truetype");
    font-weight:normal;
    font-style:normal;
  }
  
  @font-face {
    font-family: "Star Jedi Outline";
    src:url("../fonts/StarJediOutline-y0xm.ttf") format("truetype");
    font-weight:normal;
    font-style:normal;
  }
  
  @font-face {
    font-family: "Star Jedi Rounded";
    src:url("../fonts/StarJediRounded-jW3R.ttf") format("truetype");
    font-weight:normal;
    font-style:normal;
  }