.Services {
    display:flex;
    flex-direction:column;
    text-align:center;
    background-color:black;
}

.service {
    display:flex;
    flex-direction:column;
    align-items:center;
    text-align:center;
    padding:20px;
    border:1px solid var(--blueaccent);
    border-radius:25px;
    margin:20px 40px 10px 40px;
    box-shadow: inset 0 -10px 5px -8px var(--blueaccent);
}

.service h2 {
    margin-bottom:20px;
}

.content {
    display:flex;
    width:100%;
    align-items:stretch;
    height:auto;
}

.content p {
    width:50%;
    margin-right:20px;
}

.content img {
    height:25vh;
    width:50%;
    object-fit:cover;
    border-radius:25px;
    margin-top:20px;
}

@media (max-width:768px) {
    .content {
        flex-direction: column;
        align-items: center;
    }

    .content p, .content img {
        width: 100%; /* Make text and images take full width */
        margin-right: 0; /* Remove margin-right from paragraph */
    }

    .content img {
        height: auto; /* Adjust height to maintain aspect ratio */
    }
}