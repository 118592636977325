* {
  box-sizing: border-box;
}

:root {
  --standardyellowtext: rgb(254,197,1);
  /* HEX for this is FEC501 */
  --texthover: #FFEECA;
  --blueaccent: rgb(97, 206, 226);
  --boxbackground: rgba(40, 46, 50, 0.9);
  width: 100%;
}



html, body {
  height: 100%;
  margin: 0;
  padding: 0 0 6vh 0;
  color: var(--standardyellowtext);
  width: 100%;
}

.App {
  height:100%;
  width: auto;
  text-align: center;
  background-color: black;
  display:flex;
  flex-direction: column;
}

.main {
  flex:1;
}

@media (prefers-reduced-motion: no-preference) {
 
}

@font-face {
  font-family: "Star Jedi";
  src:url('../fonts/StarJedi-DGRW.ttf') format('truetype');
  font-weight:normal;
  font-style:normal;
}

@font-face {
  font-family: "Star Jedi Hollow";
  src:url("../fonts/StarJediHollow-A4lL.ttf") format("truetype");
  font-weight:normal;
  font-style:normal;
}

@font-face {
  font-family: "Star Jedi Outline";
  src:url("../fonts/StarJediOutline-y0xm.ttf") format("truetype");
  font-weight:normal;
  font-style:normal;
}

@font-face {
  font-family: "Star Jedi Rounded";
  src:url("../fonts/StarJediRounded-jW3R.ttf") format("truetype");
  font-weight:normal;
  font-style:normal;
}

.hollow {
  font-family: "Star Jedi Hollow";
  color: var(--standardyellowtext);
  font-size:0.7em;
}

.standard {
  font-family: "Star Jedi";
  color: var(--standardyellowtext);
  font-size:0.7em;
}

.rounded {
  font-family: "Star Jedi Rounded";
  color: var(--standardyellowtext);
  font-size:0.7em;
}

.outline {
  font-family: "Star Jedi Outline";
  color: var(--standardyellowtext);
  font-size:0.7em;
}

.testing {
  font-family:"Star Jedi Outline";
}

.bodytext {
  font-family:'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
  color: var(--standardyellowtext);
}

