* {
    margin: 0;
    padding: 0;
}

.Contact {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    height: auto;
    padding:0;
    margin:0;
    background-color:black;
}

#contactdetails {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width:75%;
    margin:10px auto 10px auto;
    padding:5px;
    font-size:0.6rem;
    background-color: var(--boxbackground);
    border: solid 1px var(--blueaccent);
    border-radius:10px;
    box-shadow: 2px 2px 2px var(--blueaccent);
}

#contactdetails p {
    margin: 0;
    color:var(--standardyellowtext);
    font-size:1.5rem;
}

.contactform {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width:75%;
    margin: 10px auto 10px auto;
    font-size:0.4rem;
    background-color: var(--boxbackground);
    border: solid 1px var(--blueaccent);
    border-radius:10px;
    padding:5px 5px 10px 5px;
    box-shadow: 2px 2px 2px var(--blueaccent);
}

h1 {
    color:var(--standardyellowtext);
    font-size:0.8rem;
}

input {
    width:60%;
    margin: 5px auto 5px auto;
    background-color:  rgb(78, 59, 0);
    border:none;
    text-align:center;
    color :var(--standardyellowtext);
}

button {
    width:35%;
    height:30px;
    margin-top:5px;
    background-color:var(--standardyellowtext);
    border:none;
    border-radius:5px;
    color:var(--boxbackground);
    box-shadow: 3px 3px 3px rgba(254,197,1,0.5);
    font-weight:bold;
}

button:hover {
    background-color: var(--blueaccent);
    color:rgb(78, 59, 0);
    cursor:pointer;
}

#message {
    height:20vh;
}