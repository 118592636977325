.About {
    display:flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin:30px auto 10px auto;
}

.About ul {
    list-style-type: none;
    text-align:left;
    font-size:1.5em;
}

.About ul li:before {
    content: "";
    background-image: url('../images/bullet.svg');
    background-size: cover;
    display:inline-block;
    width:10px;
    height:10px;
    margin-right:5px;
}

.About h1 {
    font-size:2em;
    margin-bottom:20px;
}